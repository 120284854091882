/** {box-sizing: border-box}*/
body {
	background: #FCFCFC;
	font-family: roboto;
	color: #333;
	margin: 0;
}

a {color: inherit;}

.teste {font-size: 12px;}
p strong, .purple-text {color: #800080;}

#mdlLayoutContainer {
	/*scroll-behavior: smooth;
	transition: all .3s ease;*/
}
/*manipulando grids*/
/*.mdl-grid, .mdl-layout {display: table-cell; width: 100%; display: flex}*/

.mdl-grid .mdl-grid {padding:0; margin: 0 -10px;}
.mdl-grid  .mdl-grid .mdl-cell {margin: 0 10px;}

.mdl-grid .mdl-grid.mdl-grid--no-spacing , .mdl-grid .mdl-grid.mdl-grid--no-spacing .mdl-cell {margin: 0;}

.header-app-sonhos {background: #FFF; color: #666;}
.header-app-sonhos a, .header-app-sonhos .mdl-navigation a {color: #666;}

.header-app-sonhos__logo {
	display: inline-block;
	width: 85px;
}

	.header-app-sonhos__logo img {
		display: inline-block;
		width: 100%;
		max-width: 100%;
	}

	/* Disable ugly boxes around images in IE10 */
	a img {border: 0px;}

	/*::-moz-selection {
		background-color: #F2561F;
		color: #fff;
	}

	::selection {
		background-color: #F2561F;
		color: #fff;
	}*/

	.sonhos-search-box .mdl-textfield__input {color: rgba(0, 0, 0, 0.87);}

	.sonhos-header .mdl-menu__container {
		z-index: 50;
		margin: 0 !important;
	}


	.mdl-textfield--expandable {width: auto;}

	.sonhos-fab {
		position: absolute;
		right: 20%;
		bottom: -26px;
		z-index: 3;
		background: #64ffda !important;
		color: black !important;
	}

	.sonhos-mobile-title {display: none !important;}

	.sonhos-logo-image {
		height: 28px;
		width: 140px;
	}

	.sonhos-header {
		overflow: visible;
		background-color: white!important;
		background: #FFF!important;
		min-height: 50px;
	}

		.sonhos-header.mdl-layout__header {box-shadow: none;}

		.sonhos-header .mdl-layout__drawer-button {display: none;}
		.sonhos-header .mdl-layout__header-row { padding: 0 40px 0 60px;}

		.sonhos-header .material-icons {color: #767777 !important;}

	.sonhos-log-btn-top {
		/* background: #E62F8F; */
		height: 32px;
		display: inline-block;
		font-size: .850em!important;
		line-height: 32px;
		padding: 0 8px;
		min-width: auto;
		color: #666;
		text-decoration: none;
	}

		.sonhos-log-btn-top:last-child {padding-right: 0;}

		.sonhos-log-btn-top:hover {color: #E62F8F;}

		.sonhos-log-btn-top .material-icons {
			font-size: 16px;
			color: inherit;
			vertical-align: middle;
			height: 32px;
			line-height: 31px;
		}

		.sonhos-header .mdl-layout__drawer-button {
			background: transparent;
			color: #767777;
		}

		.mdl-layout__drawer-button {
			color: #999!important;
			/*top:24px!important;*/
			display: none!important
		}

		.sonhos-header .mdl-navigation__link {
			color: #757575;
			font-weight: normal;
			font-size: 14px;
		}

		.sonhos-navigation-container {
			/* Simple hack to make the overflow happen to the left instead... */
			/*direction: rtl;*/
			-webkit-box-ordinal-group: 2;
			-webkit-order: 1;
					-ms-flex-order: 1;
							order: 1;
			/*width: 500px;*/
			width: 100%;
			-webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1),
					width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
							transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1),
					width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
			position: relative;
		}

		.sonhos-navigation {
			/* ... and now make sure the content is actually LTR */
			direction: ltr;
			-webkit-box-pack: end;
			-webkit-justify-content: inherit;
					-ms-flex-pack: end;
							justify-content: inherit;
			/*width: 800px;*/
		}

		.navigation-links {padding: 0 16px;}

		.navigation-links a {
			font-family:Arial;
			display: inline-block;
			color: #A0A0A0;
			text-decoration: none;
			font-size: 11px;
			padding: 2px 5px;
			background: transparent;
		}

			.navigation-links a:hover {color: #666;}

		.fix-with-top {padding: 0 40px;}

		.sonhos-search-box.is-focused ~ .sonhos-navigation-container {
			opacity: 0;
			width: 100px;
		}

		.sonhos-search-topo {
			float: right;
			margin-top: 8px;
		}

			.sonhos-search-topo input {
				padding: 0;
				width: 250px;
				background: #FFF;
				font-size: 14px;
				border: none;
				padding-left: 10px;
				padding-right: 10px;
				box-shadow: 0 1px 0 #CCC;
				transition: all .2s ease;
			}

				.sonhos-search-topo input:hover, .search-field-mob:focus, .search-field-mob:active, .sonhos-search-topo input:focus, .sonhos-search-topo input:active {
					background: #EFEFEF;
					transition: all .5s ease;
					box-shadow: 0 2px 0 #E62F8F;
					outline: none;
					border-bottom: none;
				}

			.sonhos-search-topo .mdl-button,  .sonhos-search-topo input {
				font-size: 12px;
				height: 32px;
				line-height: 32px;
				color: #666;
			}

			.sonhos-search-topo .mdl-button {padding: 0;min-width:auto;}
			.sonhos-search-topo .mdl-button:focus:not(:active) {background: transparent;}
			.sonhos-search-topo .mdl-button:hover {
				background: transparent;
				color: #E62F8F;
			}

		.form-sonhos-search {
			padding: 1em;
			/*background: purple;
			color: #FFF;*/
		}

			.form-sonhos-search > h1 {
				color: purple;
				font-size: 1.8em;
				/*font-weight: bold;*/
				margin: 0 0 8px;
			}

			.form-sonhos-search > p {
				margin: 0;
				font-size: 1em;
			}

			.form-sonhos-search .sonhos-search-topo input {
				width: calc( 100% - 60px);
			}

			.form-sonhos-search .sonhos-search-topo button {
				width: 25px;
			}

		.sonhos-navigation .mdl-navigation__link {
			background-color: transparent !important;
			border-bottom: 4px solid transparent;
			display: inline-block;
			font-family: roboto;
			font-size: 0.850em;
			/*font-weight: 400;*/
			height: 47px;
			line-height: 55px;
			padding: 0;
			margin: 0.850rem;
			white-space: nowrap;
		}

			.sonhos-navigation .mdl-navigation__link:first-child {margin-left: 0;}
			.sonhos-navigation .mdl-navigation__link:last-child {margin-right: 0;}

		.link-beleza:hover, .link-beleza.active  {border-bottom: 3px solid #E62F8F; color:#E62F8F;}

		/*.mdl-navigation__link.link-beleza  {color:#E62F8F;}*/
		.link-cabelo:hover, .link-cabelo.active  {border-bottom: 3px solid #E62F8F; color:#E62F8F;}
		/*.mdl-navigation__link.link-cabelo  {color:#E62F8F;}*/
		.link-s-sonhos:hover #sub-menu-s-sonhos  {
			display: block;
		}
		.link-s-sonhos:hover, .link-s-sonhos.active  {border-bottom: 3px solid #B42177; color:#B42177;}
		/*.mdl-navigation__link.link-s-sonhos  {color:#B42177;}*/
		.link-amor-sexo:hover, .link-amor-sexo.active  {border-bottom: 3px solid #E7213A; color:#E7213A;}
		/*.mdl-navigation__link.link-amor-sexo {color:#E7213A;}*/
		.link-moda:hover, .link-moda.active  {border-bottom: 3px solid #E54C25; color:#E54C25;}
		/*.mdl-navigation__link.link-moda {color:#E54C25;}*/
		.link-receitas:hover, .link-receitas.active  {border-bottom: 3px solid #EACA04; color:#EACA04;}
		/*.mdl-navigation__link.link-receitas {color:#C89F00;}*/
		.link-bem-estar:hover, .link-bem-estar.active  {border-bottom: 3px solid #A1C800; color:#A1C800;}
		/*.mdl-navigation__link.link-bem-estar {color:#A1C800;}*/
		.link-horoscopo:hover, .link-tarot:hover, .link-horoscopo.active, .link-tarot.active  {border-bottom: 3px solid #129860; color:#129860;}
		/*.mdl-navigation__link.link-horoscopo,.mdl-navigation__link.link-tarot {color:#129860;}*/
		.link-familia:hover, .link-familia.active  {border-bottom: 3px solid #1FB8F2; color:#1FB8F2;}
		/*.mdl-navigation__link.link-familia {color:#1FB8F2;}*/

		.mini-banner-tarot {
			text-decoration: none;
			background: #C12D84;
			color: #FFF;
			text-align: center;
			height: 50px;
			padding: 10px;
			box-sizing: border-box;
		}
			.mini-banner-tarot:hover {background: #910091}

		.sonhos-header-row {
			background:#E9E9E9;
			margin-top: 0;
		}

			#mdl-navigation-mega-menu {
				position: absolute;
				width: 100%;
				max-width: 1200px;
				/*margin: 0 auto;*/
				height: auto;
				padding: 0;
				left: 0;
				margin: 0;
				box-sizing: border-box;
				list-style:none;
				text-align: left;
				font-family: roboto;
				z-index: 1000;
			}

				#mdl-navigation-mega-menu > li {
					box-sizing: border-box;
					width: 100%;
					padding: 2.5em 4em;
					transition: all ease .5s;
					display: none;
					width: 100%;
					box-shadow:0 5px 3px rgba(0, 0, 0, 0.2);
				}

					.mdl-navigation-post-links {
						display: flex;
						width: 100%;
						margin: 0;
						padding: 0;
						box-sizing: border-box;
					}

						.mdl-navigation-post-links *, #mdl-navigation-mega-menu * {box-sizing: border-box;}

						.content-list-posts * {box-sizing: border-box;}

							.content-list-posts-title {
								padding: 0 10px;
								border-bottom: 1px solid #DDD;
								height: 35px;
							}

								.content-list-posts-title h3 {
									margin: 0;
									font-size: 16px;
									text-transform: uppercase;
									color: purple;
									line-height: 100%;
									font-weight: bold;
									line-height: 35px;
								}

							.list-post {
								/*height: 110px;*/
								background: #fff none repeat scroll 0 0;
								border-bottom: 1px solid #ddd;
								display: flex;
								flex-direction: row-reverse;
								padding: 10px;
								width: 100%;
							}

							/*.list-post:hover {background: #FEFEFE;}*/
								.list-post:last-child {border-bottom: none;}

							.list-post-text {
								width: 100%;
								display: flex;
								align-items: center;
								font-family: Roboto;
								float: left;
								padding-right: 10px;
							}


							.list-post-media  + .list-post-text {width: 75%;}

								.list-post-text-title {
									font-size: 1em;
									color: #666;
									line-height: normal;
									margin: 0;
								}

								.list-post-text-title a {
									text-decoration: none;font-weight: normal;
									font-size: 14px;
									letter-spacing: 0;
									margin: 0 0 16px;
								}
									.list-post-text-title a:hover {color: #800080; background: transparent;}

									.list-post-text-text {margin: 0;}

									.list-post-text-link {
										color: #999;
										display: flex;
										font-size: 13px;
										align-items: center;
										font-weight: bold;
										line-height: 100%;
										margin-top: 5px;
										text-decoration: none;
									}

										.list-post-text-link button .material-icons {font-size: 20px;}

										.list-post-text-link:hover {
											background: transparent!important;
											color: #666;
										}

								.list-post-media {
									width: 25%;
									float: right;
								}

									.list-post-media img {width: 100%;}

						.mdl-navigation-links, .mdl-navigation-post {
							float: left;
							list-style: none;
						}

						.mdl-navigation-links {width: 100%; margin-right: 1em;}

						/*.mdl-navigation-post + .mdl-navigation-links {width: 70%;}*/

						.mdl-navigation-post {
							padding: 0;
							width: 35%;
							order: 1;
							/*background: #F5F5F5;*/
							border-radius: 2px;
						}

						.mdl-navigation-box {
							width: 40%;
							float: left;
						}

					#mdl-navigation-mega-menu > li:hover, #mdl-navigation-mega-menu > li.active {display: table!important;}

					#mdl-navigation-mega-menu > li > a, .mdl-navigation-post-links .mdl-navigation-links a {
						/*width: calc(20% - 15px);*/
						text-decoration: none;
						display: block;
						color: #666;
						text-transform: capitalize;
						border-bottom: 1px dotted rgba(0,0,0,0.1);
						padding: .5em;
						margin: 0 15px 0 0;
						font-weight: normal;
						transition: all ease .5s;
						font-size: 14px;
						/*float: left;*/
					}

						#mdl-navigation-mega-menu  li > a:hover {transition: all ease .5s; font-weight: bold;}

						/*.sub-menu-beleza {background: #E62F8F;}
							.sub-menu-beleza li > a:hover {background: #CB1776;}*/

						.sub-menu-cabelo,.sub-menu-beleza  {
							background:#F5F5F5;
							border-top: 2px solid #E62F8F;
						}
						.sub-menu-cabelo li > a,.sub-menu-beleza li > a {color: #E62F8F!important;}
							.sub-menu-cabelo li > a:hover,.sub-menu-beleza li > a:hover {background: #FED1E8;}

						.sub-menu-s-sonhos {
							display: none;
							background: #F5F5F5;
							border-top: 2px solid #b42177;
						}
							.sub-menu-s-sonhos li > a {color:#b42177!important;}
							.sub-menu-s-sonhos li > a:hover {background: #EACDEA;}

						.sub-menu-amor-sexo {background: #E7213A;}
							.sub-menu-amor-sexo li > a:hover {background: #C80821 ;}

						.sub-menu-moda {background: #E54C25;}
							.sub-menu-moda li > a:hover {background: #C13D1B;}

						.sub-menu-receitas {background: #EACA04;}
							.sub-menu-receitas li > a:hover {background: #CBAF02 ;}

						.sub-menu-bem-estar {background: #A1C800;}
							.sub-menu-bem-estar li > a:hover {background: #8AAB01;}

						.sub-menu-horoscopo {
							/*background: #C8FECB;*/
							background: #F5F5F5;
							border-top:2px solid #129860;
						}
							.sub-menu-horoscopo li > a {color: #129860!important;}
							.sub-menu-horoscopo li > a:hover {background: #ADEAC6;}

						.sub-menu-familia {
							background:#F5F5F5;
							border-top: 2px solid #1FB8F2;
						}
							.sub-menu-familia li > a {color: #1FB8F2!important;}
							.sub-menu-familia li > a:hover {background: #C4EBFE;}

			/*.sonhos-navigation .mdl-navigation__link:hover {}*/

		#menu-lower-right {display: none;}

			.menu-lower-right li a {color: inherit; text-decoration: none;}

		.sonhos-search-box {
			/*-webkit-box-ordinal-group: 3;
			-webkit-order: 2;
					-ms-flex-order: 2;
							order: 2;*/
			margin-left: 16px;
			margin-right: 0;
		}

		.sonhos-more-button {
			-webkit-box-ordinal-group: 4;
			-webkit-order: 3;
					-ms-flex-order: 3;
							order: 3;
		}


	.sonhos-drawer {
		border-right: none;
	}

	.sonhos-header .mdl-layout__header-row {
			padding: 0 16px;
			height: 50px;
	}

	.sonhos-header  .mdl-layout__header-row .mdl-navigation {
		height:50px;
	}

		.sonhos-title, .sonhos-search-box {display: none;}

		.sonhos-drawer-separator {
			height: 1px;
			background-color: #dcdcdc;
			margin: 8px 0;
		}

		.sonhos-drawer .mdl-navigation__link.mdl-navigation__link.sonhos-drawer-link-title {
			color: #B42177; font-weight: bold;
			text-transform: uppercase;
		}

		.sonhos-drawer .mdl-navigation__link.mdl-navigation__link {
			font-size: 12px;
			color: #757575;
			text-transform: capitalize;
		}

		.sonhos-drawer .mdl-layout-title {
			position: relative;
			background: #6ab344;
			height: 160px;
		}

	.site-content {
		padding: 2em .8em;
	}

	.sonhos-box-search {
		background: #B42177;
		padding: .5em 1em;
		border-radius: 2px;
		margin-bottom: 1em;
	}

/*manipulando grids*/
.mdl-grid .mdl-grid {padding:0; margin: 0 -10px;}
.mdl-grid  .mdl-grid .mdl-cell {margin: 0 10px;}

.mdl-grid .mdl-grid.mdl-grid--no-spacing , .mdl-grid .mdl-grid.mdl-grid--no-spacing .mdl-cell {margin: 0;}

.site-content, .layout-width {
	width: 100%;
	max-width: 1200px;
	box-sizing: border-box;
	margin: 0 auto;
}

.bread-crumb {color:#ADADAD;font-size: 12px;}

	.bread-crumb a {
			color:#999;
			font-weight: normal;
			text-decoration: none;
			padding-right: 8px;
	}

		.bread-crumb a:hover {color: #666;}

		.bread-crumb a::after {
			content: '\203A';
			margin-left: 8px;
		}

/*carousel mini galeria*/
.mini-gal-card {
	width: auto;
	overflow: hidden;
}

	.mini-gal-card-container {
		/*padding: 0 20px;*/
		box-sizing: border-box;
		position: relative;
	}

		.owl-theme .owl-controls {
				margin-top: 0;
				position: absolute;
				text-align: center;
				top: calc(50% - 35px);
				width: 100%;
		}

		.owl-theme .owl-controls .owl-buttons div {
			background: #E62F8F;
			color: #FFF;
			font-size: 50px;
			height: 35px;
			margin: 0;
			opacity: 0.8;
			text-align: center;
			width: 35px;
			align-items: center;
			display: flex;
			justify-content: center;
			padding: 2px 5px 7px 5px;
			transform: translateZ(-1px;);
		}

		.owl-next {float: right;margin: 0 0 0 -3px;padding: 2px 3px 7px 6px !important;}
		.owl-next:before {content: '\203A'}
		.owl-prev {float: left;margin: 0 -3px 0 0; padding: 2px 7px 7px 2px !important;}
		.owl-prev:before {content: '\2039'}

	.mini-gal-card-box {
		width: 150px;
		background: #FFF;
		margin:5px auto;
		border-radius: 2px;
		overflow: hidden;
		height: 250px;
		max-height: 250px;
	}

		.mini-gal-card-box-link {
			display: block;
			text-decoration: none;
		}

			.mini-gal-card-box-link:hover {color: purple;}

		.mini-gal-card-box-img {}

		.mini-gal-card-box-desc {
			font-size: 14px;
			margin: 0;
			line-height: 140%;
			padding: 1em;
		}

.pagination-links {
	margin: 0; padding: 0;
	list-style: none;
	text-align: center;
	height: auto;
	display: flex;
	justify-content: center;

}

	.pagination-links li {
		display: flex;
		justify-content: center;
		max-height: 40px;
	}

		.pagination-links li a {
			display: inline-block;
			align-self: center;
			padding: 0.5em 0.8em;
			text-decoration: none;
		}

			.pagination-links li a.active, .pagination-links li a:hover {
				background: #F5F5F5;
			}

			.pagination-links li a .material-icons {margin-top: 3px;}
			.pagination-links li a.arrows-b-f {background: transparent;}
			.pagination-links li a.arrows-b-f:hover {color: purple;}

/*card cadastro*/
.sonho-card__cadastro.mdl-card {margin-bottom: 0!important;}

.sonho-card__cadastro {
	background: #E9E9E9;
	flex-direction: row;
	min-height: auto;
	width: 100%;
	justify-content: center;
}

	.sonho-card__cadastro--vertical, .sonho-card__cadastro.sonho-card__cadastro--vertical {
		flex-direction: column;
		text-align: center;
		justify-content: flex-start;
	}

	.sonho-card__cadastro--white {background: white;}

	.sonho-card__cadastro .mdl-card__actions {
		width: auto;
	}

	.sonho-card__cadastro .mdl-card__title, .sonho-card__cadastro .mdl-card__actions {
		padding: 2rem;
		align-self: center;
		border: none;
		/*background: #c12d84;*/
	}

		.sonho-card__cadastro .mdl-card__title .mdl-card__title-text {overflow: visible;} /*just for IE */

		.sonho-card__cadastro--vertical .mdl-card__title, .sonho-card__cadastro--vertical .mdl-card__actions, .sonho-card__cadastro--vertical .mdl-card__supporting-text{
			padding: 1rem!important;
		}

			.sonho-card__cadastro--vertical .mdl-card__title {padding-bottom: 0!important;}

		.sonho-card__cadastro .mdl-card__supporting-text {
			padding: 2rem;
			align-self: center;
			font-size: 1.2rem;
			line-height: 120%;
			/*width: auto;*/
		}

			.flex-grow-2 {flex-grow: 2;}

			.b-radius {border-radius: 3px!important;}

			.sonho-card__cadastro .mdl-card__supporting-text p {font-size: 1.2rem;}

		.sonho-card__cadastro .mdl-card__title .material-icons {
			color: #999;
			font-size: 8rem;
			line-height: 100%;
		}


/*Card post*/
.mdl-card-post {overflow: visible; /*background: transparent;*/}

.mdl-card-post .mdl-card__title {
	flex-direction: column;
	align-items: flex-start;
	border: 0;
}

	.mdl-card-post .mdl-card__title, .mdl-card-post .mdl-card__supporting-text {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.mdl-card__supporting-text--post p {
		font-size: 1rem;
		color: rgba(0,0,0,0.8);
	}

.mdl-card-post .mdl-card__title-text {
	color: purple;
	font-size: 3rem;
	align-self: flex-start;
}

	.mdl-card__ads-post {
		float: left;
		min-width: 300px;
		margin: 0 1rem 1rem 0;
	}

	.mdl-card__materias-outras {
		float: left;
		max-width: 300px;
		margin: 0 1rem 1rem 0;
		border: 1px solid #DDD;
	}

		.mdl-card__materias-outras.right {
			float: right;
			max-width: 300px;
			margin: 0 0 1rem 1rem;
		}

		.mdl-card__materias-outras .list-post {
			padding: 10px!important;
			box-shadow: none!important;
		}

			.mdl-card__materias-outras .list-post-text-title a {
				font-weight: bold;
				color: #E62F8F;
			}

				.mdl-card__materias-outras .list-post-text-title a:hover {color: purple;}

.mdl-card-post .mdl-card__title-subtext {
	color: #ABABAB;
	font-size: 1.5rem;
	margin: 0;
	line-height: normal;
	overflow: visible;
}

.mdl-card-post__footer-post {margin: 0 0 1rem 0;}
	.mdl-card-post__footer-post a {text-decoration: none;}
	.mdl-card-post__footer-post a:hover {color: purple;}

	.mdl-card-post__footer-post-title {
		margin: 0;
		font-size: 1.2rem;
		color: purple;
	}

.mdl-card-post__search-result {
	font-size: 1.2rem;
	margin-bottom: 1rem;
}

	.mdl-card-post__search-result a {color: purple; text-decoration: none;}
	.mdl-card-post__search-result a:hover {text-decoration: underline;}

.mdl-card-post__title {
	font-size: 1.2rem;
	margin: 0 0 1rem 0;
}

.mdl-card-post__text {clear: both;}

.mdl-card-post__text a {text-decoration: none;}

.mdl-card-post__text--anchors p {
	display: table;
	width: 100%;
}

.mdl-card-post__outros-sonhos {
	padding: 2rem 0;
	clear: both;
}

	.mdl-card-post__outros-sonhos .outros-sonhos-img {
		float: left;
		margin: 0 1rem 1rem 0;
		max-width: 60px;
		max-height: 60px;
	}

		.outros-sonhos-links a {
			color: purple;
			font-weight: normal;
			text-decoration: none;
		}

			.outros-sonhos-links a:hover {text-decoration: underline;}

.mdl-card-post__text-navega {
	margin: 2rem 0;
	/*padding: 1rem 0;*/
	border: 1px solid #DDD;
	/*border-bottom: 1px solid #DDD;*/
}

	.mdl-card-post__text-navega-title {
		font-size: 18px;
		margin: 0;
		color: purple;
		padding: .550rem 1rem .550rem 1rem;
		line-height: normal;
	}

	.mdl-card-post__text-navega p {clear: both;}

	.mdl-card-post__text-navega-lista {
		margin: 0;
		overflow: visible;
		padding: 0;
		list-style-position: inside;
	}

		.mdl-card-post__text-navega-lista li {
			border-top: 1px solid #DDD;
			padding-left: 1rem;
			line-height: normal;
		}

			.mdl-card-post__text-navega-lista li a {
				display: block;
				padding: 10px 1rem!important;
				color: #e62f8f!important;
			}

	.mdl-card-post__footer-links {
		padding: 1rem 0 1rem 30px;
		margin: 0;
		/*list-style: none;*/
	}

	.mdl-card-post__rate {margin: 1rem 0;}

		.mdl-card-post__rate-box {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.5rem;
			color: #FFF;
			text-align: center;
			height: 100%;
			text-decoration: none;
			padding: .5rem;
			border-radius: 3px;
			box-sizing: border-box;
		}

			.mdl-card-post__rate-box.rate-box-text {
				color: #666;
				line-height: 120%;
			}

			.mdl-card-post__rate-box .material-icons {font-size: 3rem;}

			.mdl-card-post__rate-box.rate-box-like {background: #4BAE4F;}
				.mdl-card-post__rate-box.rate-box-like:hover {background: #4B9F27;}

			.mdl-card-post__rate-box.rate-box-unlike {background:#DDD; color: #666;}
				.mdl-card-post__rate-box.rate-box-unlike:hover {background: #CCC;}

	.mdl-card-post__options {
		margin: 0 0 1rem 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-content: stretch;
		border-radius: 3px;
		overflow: hidden;
		background: #FFF;
	}

		.mdl-card-post__options-box {
			text-align: center;
			padding: 1rem;
			box-sizing: border-box;
			flex-grow: 1;
			width: 33.3%;
			/*border: 1px solid #DDD;*/
		}

			.mdl-card-post__options-box:hover {background: #F5F5F5;}
			.mdl-card-post__options-box a {color: purple;}
			.mdl-card-post__options-box a:hover {color: #93009F;}

			.mdl-card-post__options-box {border-right: 1px solid #DDD;}
			.mdl-card-post__options-box:last-child {border-left:none; border-right:none;}
			/*.mdl-card-post__options-box:first-child {border-right:none;}*/

			.mdl-card-post__options-box-title {
				font-size: 1.2rem;
				font-weight: bold;
				margin: 0 0 1rem 0;
				line-height: normal;
				/*color: purple;*/
			}

	.mdl-card-post__footer-links {padding: 0;}

		.mdl-card-post__text-navega-lista li, .mdl-card-post__footer-links li {font-size: 1rem;}

		.mdl-card-post__text-navega-lista li a, .mdl-card-post__footer-links li a {
			display: inline-block;
			font-weight: bolder;
			color: purple;
			text-decoration: none;
			margin: .250rem 0;
		}

		.mdl-card-post__footer-links li a {font-size: 14px; color: #666;}

		.mdl-card-post__text-navega-lista li a:hover, .mdl-card-post__footer-links li a:hover, {text-decoration: underline;}

		.mdl-card-post__text-navega-lista li a:hover {color: purple!important}

	.box-post-horoscopo {
		display: flex;
		justify-content: row;
		flex-wrap: wrap;
	}

		.box-post-horoscopo a {
			display: block;
			width: 8.333%;
			text-align: center;
			font-size: 0.750rem;
			font-weight: normal;
			margin-top: 1rem;
			box-sizing: border-box;
			/*border: 1px solid #FFF;*/
			padding: 0 .2rem;
			transition: transform .2s ease;
		}

			.box-post-horoscopo a:hover {transform: scale(1.2);}

			.box-post-horoscopo a:hover img {
				/*border: 1px solid #DDD;*/
				/*filter: grayscale(0);
				opacity: 1;*/
			}

			/*.box-post-horoscopo a span {display: none;}*/

			.box-post-horoscopo a img {
				width: 100%;
				max-width: 60px;
				margin-bottom: 5px;
				/*filter: grayscale(100%);
				opacity: .8;*/
			}

.apps-box {
	width: 50%;
	text-align: center;
	display: inline-block;
	float: left;
	font-size: 14px;
	padding: 0 5px;
	box-sizing: border-box;
	line-height: 120%;
}

	.apps-box img {
		width: 50px;
		display: inline-block;
		margin-bottom: 5px;
	}
	.apps-box span {clear: both;display: block;}

/*Card sections*/
.mdl-card-section {min-height: auto;}


/*tarot*/

.tarot-card-grid {justify-content: center;}

.tarot-card-wide .mdl-card__title, .materia-card .mdl-card__title {
	position: relative;
	color: #fff;
	min-height: 200px;
	max-height: 200px;
	text-align: center;
	background-position: center!important;
	background-size: 110%!important;
	overflow: hidden;
	background-repeat: no-repeat;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

	.tarot-card-wide:hover .mdl-card__title, .materia-card:hover .mdl-card__title {
		background-size: 130%!important;
	}

.tarot-card-free {background: url('https://dpc2.ftcdn.net/jpg/00/83/76/21/500_F_83762133_BcyE6lE6BpkrUjWoYVwspz3kv5nuMsyN.jpg');}
.tarot-card-espiritual {background: url('https://dpc2.ftcdn.net/jpg/00/95/49/25/500_F_95492588_Mo3wj27eNK0iLlGUwjt6saP1btXZT0Sf.jpg');}
.tarot-card-amor {background: url('https://dpc2.ftcdn.net/jpg/00/95/05/95/500_F_95059595_7nFMQ9gF4QOxRe1x3IczGnvEatzKD4vB.jpg');}
.tarot-card-sonhos {background: url('https://dpc1.ftcdn.net/jpg/00/53/20/36/500_F_53203688_bAnVBZ8h5hCsMUAJe2GV5w0br3SpJkEl.jpg');}

.tarot-card-free .shadow-descrip {background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.01), rgba(255, 239, 34, 0.55) 45%);background-image: -o-linear-gradient(rgba(255, 255, 255, 0.01), rgba(255, 239, 34, 0.55) 45%);background-image: linear-gradient(rgba(255, 255, 255, 0.01), rgba(255, 239, 34, 0.55) 45%);}
.tarot-card-espiritual .shadow-descrip {background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.01), rgba(33, 173, 96, 0.67) 45%);background-image: -o-linear-gradient(rgba(255, 255, 255, 0.01), rgba(33, 173, 96, 0.67) 45%);background-image: linear-gradient(rgba(255, 255, 255, 0.01), rgba(33, 173, 96, 0.67) 45%);}
.tarot-card-amor .shadow-descrip {background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.01), rgba(238, 41, 61, 0.60) 45%);background-image: -o-linear-gradient(rgba(255, 255, 255, 0.01), rgba(238, 41, 61, 0.60) 45%);background-image: linear-gradient(rgba(255, 255, 255, 0.01), rgba(238, 41, 61, 0.60) 45%);}

.tarot-card-wide > .mdl-card__title > a {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: 1000;
	text-decoration: none;
}

.tarot-card-wide > .mdl-card__title .mdl-card__title-text {
	z-index: 1000;
	font-size: 35px;
	display: block;
	width: 100%;
	font-weight: 300;
	text-shadow: 0 3px 6px #000, 0 1px 2px rgba(0,0,0,0.5);
	align-self: center;
}

	.tarot-card-wide > .mdl-card__title .mdl-card__title-text.f-size-25 {font-size: 25px;}

	.tarot-card-wide > .mdl-card__supporting-text {
		font-size: 15px;
		/*color: #666;*/
		line-height: 140%;
		/*font-weight: 300;*/
		padding-top:20px;
		padding-bottom: 20px;
	}

	.tarot-card-wide > .mdl-card__actions {padding-left: 16px;}

	.tarot-card-wide .mdl-card__actions {
			display: flex;
			box-sizing:border-box;
			align-items: center;
	}

	.tarot-card-wide .mdl-card__actions{
			height: 70px;
	}

	.tarot-card-wide .mdl-card__actions > .mdl-button--icon {
			margin-right: 3px;
			margin-left: 3px;
	}

	.shadow-descrip {
			background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.01), rgba(128, 0, 127, 0.65) 45%);
			background-image: -o-linear-gradient(rgba(255, 255, 255, 0.01), rgba(128, 0, 127, 0.65) 45%);
			background-image: linear-gradient(rgba(255, 255, 255, 0.01), rgba(128, 0, 127, 0.65) 45%);
			bottom: 0;
			height: 100%;
			display: block;
			font-size: 0.8em;
			font-weight: lighter;
			left: 0;
			line-height: 140%;
			padding: 2.8em 0.8em 0.8em;
			position: absolute;
			width: 100%;
			z-index: 900;
			box-sizing: border-box;
	}
/*fim estilos para tarot*/

/*cards materias*/
.materia-card {}
.materia-card .mdl-card__title {
	overflow: hidden;
	min-height: 120px;
	max-height: 120px;
}

.materia-card .mdl-card__title {border-bottom: none;}
/*.materia-card .mdl-card__supporting-text { padding-top: 0; padding-bottom: 0;}*/
.materia-card .mdl-card__supporting-text p {font-size: 14px; padding-top: 0; padding-bottom: 0; margin: 0;}
.materia-card .mdl-card__title .mdl-card__title-text {
	font-weight: 300;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	z-index: 1000;
	text-align: left;
}

	.materia-card .mdl-card__title .mdl-card__title-text-small {
		font-size: 18px;
		font-weight: normal;
	}

.materia-card .mdl-card__supporting-text p a {
	text-decoration: none;
	font-weight: normal;

}

.materia-card .mdl-card__media { margin: 0; }
.materia-card .mdl-card__media > img { max-width: 100%; }
.materia-card .mdl-card__actions {
	display: flex;
	box-sizing:border-box;
	align-items: center;
}

.materia-card .mdl-card__actions > .mdl-button--icon {
		margin-right: 3px;
		margin-left: 3px;
}

.materia-card .shadow-descrip {
	background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.01), rgba(0,0,0,0.65) 45%);
	background-image: -o-linear-gradient(rgba(255, 255, 255, 0.01), rgba(0,0,0,0.65) 45%);
	background-image: linear-gradient(rgba(255, 255, 255, 0.01), rgba(0,0,0,0.65) 45%);
}

.mdl-button {color: purple;}

/*estilos para cards padrÃ£o*/
.mdl-card--areas {border-radius: 0 0 3px 3px; }

.mdl-card--areas .mdl-button {
	/*color: purple*/
}

.mdl-card--areas .mdl-card__title {
	/*border-top: 3px solid purple;*/
	border-top: none;
	border-bottom: 1px solid #DCDCDC;
	color: purple;
	/*padding-bottom: 0;*/
	display: block;
	background: #F5F5F5;
}

	.mdl-card--areas .mdl-card__title-text-comp {
		margin:0;
		text-transform: uppercase;
		font-size: 12px;
		color: #999;
		line-height: normal;
	}

	.mdl-card--areas .mdl-card__title .mdl-card__title-text {
		font-weight: 500;
		font-size: 1.4rem;
	}

	.mdl-card--areas-green .mdl-card__title {/*border-top-color: #129860;*/ color: #129860;}
	.mdl-card--areas-light-green .mdl-card__title {/*border-top-color: #A1C800;*/ color: #A1C800;}
	.mdl-card--areas-blue .mdl-card__title {/*border-top-color: #1FB8F2;*/ color: #1FB8F2;}
	.mdl-card--areas-red .mdl-card__title {/*border-top-color: #E7213A;*/ color: #E7213A;}
	.mdl-card--areas-pink .mdl-card__title {/*border-top-color: #E62F8F;*/ color: #E62F8F;}

	.mdl-card--areas .mdl-button {color: purple;}
	.mdl-card--areas-green .mdl-button {color: #129860;}
	.mdl-card--areas-light-green .mdl-button {color: #A1C800;}
	.mdl-card--areas-blue .mdl-button {color: #1FB8F2;}
	.mdl-card--areas-red .mdl-button {color: #E7213A;}
	.mdl-card--areas-pink .mdl-button {color: #E62F8F;}

	.mdl-card--areas .mdl-card__actions {background: #F5F5F5;}

/*fim estilos para cards padrÃ£o*/

/*gallery-sonhos-2*/
.gallery-sonhos-2 {
	margin: 0; padding: 0;
	list-style: none;
	display: table;
	width: 100%;
}

	.gallery-sonhos-2-items {
		width: 50%;
		display: inline-block;
		float: left;
		text-align: center;
	}

		.gallery-sonhos-2-items a {
			display: block;
			padding: .2rem .5rem .5rem .5rem;
			text-decoration: none;
		}

			.gallery-sonhos-2-items a:hover {color: purple}

		.gallery-sonhos-2-items img {max-width: 100%;}

		.gallery-sonhos-2-items p {
			font-size: 1rem;
			margin: 5px 0 0 0;
			line-height: 120%;
		}

/*misc*/
.mdl-card__title a {text-decoration: none;}

.mdl-card__title--purple {background-color: purple;}
.mdl-card__title--light-green {background-color: #B0DA5E;}

.list-words {
	display: flex;
	flex-wrap: wrap;
}

	.list-words-item {
		display: block;
		padding: .5rem;
		font-size: 18px;
		text-decoration: none;
		border-radius: 3px;
		margin: .250rem 0;
	}

		.list-words-item:hover {background-color: #F5F5F5;}

.to-top-block {
	display: block;
	margin: 1rem 0;
	padding: .2rem;
	text-align: center;
	border-radius: 3px;
	box-sizing: border-box;
	border: 1px solid #DDD;
	font-size: 10px;
	cursor: pointer;
	background: #FFF;
}

.to-top-block:hover {background-color: #F5F5F5; color: purple;}

.to-top {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 3px;
	bottom: 10px;
	color: rgba(255, 255, 255, 0.8);
	height: 40px;
	opacity: 0.5;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	right: 25px;
	text-align: center;
	transition: all 0.2s ease 0s;
	display: none;
	width: 40px;
	z-index: 9999;
	-ms-transform: rotate(-90deg); /* IE 9 */
	-webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
	transform: rotate(-90deg);
	text-decoration: none;
}

.mdl-textfield--thin { padding: 0; }

.mdl-textfield--top-search { margin-right: 20px;}
.mdl-textfield--top-search .mdl-textfield__label::after { background-color: #E62F8F;}
.mdl-textfield--top-search .mdl-textfield__input {text-indent: 10px; background-color: rgba(255, 255, 255, 0.5); color: #333;}
.mdl-textfield--top-search .mdl-textfield__expandable-holder { width: 250px;}

.mdl-textfield--top-search .mdl-button--icon {
	bottom: 0;
}

.fb-comments > span, .fb-comments > span > iframe {width: 100%!important}

.rounded-button {
	border-radius: 50%;
	background: purple;
	color: #FFF;
	display: inline-block;
}

.mdl-button--colored-green {
	background:  #4BAE4F;
	color: #FFF;
}

	.mdl-button--colored-green:hover {background: #4B9F27;}

ul {list-style: none;}
.search-box {margin: 1rem 0 0 0;}

	.search-box__field {
		height: 40px;
		border: 1px solid #DDD;
		border-radius: 3px;
		padding: 0 1rem;
		box-sizing: border-box;
		width: 100%;
		font-size: 18px;
		transition: all .3s ease;
	}

		.search-box__field:hover, .search-box__field:focus {
			border: 1px solid #EE74BD;
			box-shadow: 0 0 5px rgba(180, 33, 119, 0.5);

		}

	.search-box__btn {
		height: 40px;
		line-height: 40px;
		display: inline-block;
		padding: 0 .5rem;
		background: #b42177;
		font-size: 14px;
		text-decoration: none;
		color: #FFF;
		width: 100%;
		text-align: center;
		border-radius: 3px;
		overflow: hidden;
		box-sizing: border-box;
	}
		.search-box__btn .material-icons {line-height: 40px;}
		.search-box__btn:hover {background: #A12177; }

/*input collapsed*/
.input-collapse {
	display: flex;
	flex-direction: row;
}

	.input-collapse__inp {

	}

		.input-collapse__inp input[type="text"], .input {
			height: 40px;
			border: 1px solid #DDD;
			border-radius: 3px;
			padding: 0 1rem;
			/*width: 300px;*/
			box-sizing: border-box;
			margin: 2px 0;
		}

		.input {width: 100%;}

	.input-collapse__btn {
		margin-left: -5px;
		box-sizing: border-box;
	}

		.input-collapse__btn a, .btn-def {
			display: inline-block;
			height: 40px;
			line-height: 40px;
			padding: 0 1rem;
			background: #b42177;
			font-size: 14px;
			text-decoration: none;
			color: #FFF;
			width: 100%;
			text-align: center;
			border-radius: 0 3px 3px 0;
			overflow: hidden;
			box-sizing: border-box;
			margin: 2px 0;
		}

		.input-collapse__btn a:hover, .btn-def:hover {background: #AF0875}

		.icon-gender-cadastro {
			display: inline-block;
			float: left;
			text-align: center;
			width: 50%;
			margin: 5px 0;
		}

.mdl-shadow--custom {box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);}
.at-share-btn {margin-top: 3px;}
.f-size-12, a.f-size-12, .material-icons.f-size-12  {font-size: 12px!important}
.f-size-14, a.f-size-14, .material-icons.f-size-14  {font-size: 14px!important}
.f-size-16, a.f-size-16, .material-icons.f-size-16  {font-size: 16px!important}
.f-size-18, a.f-size-18, .material-icons.f-size-18  {font-size: 18px!important}
.f-size-20, a.f-size-20, .material-icons.f-size-20  {font-size: 20px!important}
.f-size-30, a.f-size-30, .material-icons.f-size-30  {font-size: 30px!important}
.f-size-40, a.f-size-40, .material-icons.f-size-40  {font-size: 40px!important}
.f-size-50, a.f-size-50, .material-icons.f-size-50  {font-size: 50px!important}
.f-size-60, a.f-size-60, .material-icons.f-size-60  {font-size: 60px!important}
.f-size-70, a.f-size-70, .material-icons.f-size-70  {font-size: 70px!important}

/*permite alinhar um elemento Ã  esquerda no flex-box*/
.to-right {margin-left: auto;}

.hide {display: none!important;}
.show {display: block!important;}

.align-to-center {
	align-items: center;
	display: flex;
	justify-content: center;
}

.align-to-right {
	display: flex;
	align-items: end;
	justify-content: end;
}

.right {float: right;}
.left {float: left;}
.no-float {float: none;}

.margin-20 {margin: 20px;}
.margin-r-20 {margin-right: 20px;}
.margin-b-16 {margin-bottom: 16px!important;}

.img-left, .img-left-fixed {
	float: left;
	margin: 0 1rem 1rem 0
}

.img-right {
	float: right;
	margin: 0 0 1rem 1rem
}

.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}

.no-margin {margin:0!important;}
.no-margin-b {margin-bottom:0!important;}
.no-padding {padding:0!important;}
.no-margin-t-b {margin-top:0!important; margin-bottom:0!important;}
.no-margin-l-r {margin-right:0!important; margin-left:0!important;}
.no-padding-t-b {padding-top:0!important; padding-bottom:0!important;}
.no-padding-l-r {padding-right:0!important; padding-left:0!important;}

.full-width {width: 100%;}
.full-height {height: 100%;}

/*cards para nomes femininos e masculinos destaque*/
.mdl-card-sonhos-nomes {min-height: auto;}
.mdl-card-sonhos-nomes, .mdl-cell.mdl-card-sonhos-nomes, .mdl-card {margin-bottom: 20px!important;}

	.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes .mdl-card__title {padding: 20px 16px;}

 .mdl-card__title {
	background-size: cover;
	background-position: top right;
	background-repeat: no-repeat;
 }

.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-m .mdl-card__title, .mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-f .mdl-card__title, .mdl-card-sonhos-nomes.mdl-card-sonhos-update .mdl-card__title {
	border-bottom: 1px solid #DDD;
	min-height: 150px;
	max-height: 150px;
}

	/*.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-m .mdl-card__title {background-image: url('../img/bg-card-m.gif');}*/
	.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-m .mdl-card__title {position:relative; background-image: url('../img/baby-boy.jpg');}
	.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-f .mdl-card__title {position:relative; background-image: url('../img/baby-girl.jpg');}
	/*.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-f .mdl-card__title {background-image: url('../img/bg-card-f.gif');}*/
	.mdl-card-sonhos-nomes.mdl-card-sonhos-update .mdl-card__title {background-image: url('../img/bg-card-update.gif');}

	.overlay-title-card {
		position: absolute;
		top:0; left:0;
		width: 100%; height:100%;
	}

	.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-f .overlay-title-card {background: rgba(230, 46, 143, 0.2);}
	.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-m .overlay-title-card {background: rgba(31, 183, 242, 0.2);}

	.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-f .mdl-button {color: #E52E8E;}
	.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-m .mdl-button {color: #1FB8F2;}
	.mdl-card-sonhos-nomes.mdl-card-sonhos-update .mdl-button {color: #4AA23C;}
	/* fim cards para nomes femininos e masculinos destaque*/
	.mdl-card__title-text {font-weight: 400; /*color: #FFF;*/}

	.mdl-card__title-text img.icon-m-f {width: 30px;  height: 29px; margin-right: 10px;}
	.mdl-card__title-text img.icon-update {width: 35px;  height: 28px; margin-right: 10px;}
	.mdl-card__title-text img.icon-star {width: 30px;  height: 30px; margin-right: 10px;}
	.mdl-card__title-text img.icon-az {width: 26px;  height:30px; margin-right: 10px;}

	.mdl-card__title.purple-title {background-image: url(../img/bg-purple.gif);}
	.mdl-card__title.light-blue-title {background-image: url(../img/bg-light-blue.gif);}
	.mdl-card__title.green-title {background-image: url(../img/bg-green.gif);}
	.mdl-card__title.green-title {background-image: url(../img/bg-green.gif);}
	.mdl-card__title.gray-title {background-image: url(../img/bg-gray-2.gif);}
		.mdl-card__title.gray-title .mdl-card__title-text { color: #800080;}
	.mdl-card__title.pink-title {background-image: url(../img/bg-pink.gif);}

	.yellow-star {color: yellow}

	.mdl-card__title-text .material-icons {line-height: normal;}
	/*.mdl-card__title { border-bottom: 1px solid #DDD;}*/
	.mdl-card__supporting-text {width: 100%; box-sizing: border-box;}
	.mdl-card__supporting-text .list-post {padding-left: 0;padding-right: 0; font-size: 1.2em;}

	.mdl-card__supporting-text table.custom-td {
		border:0;
		width: 100%;
		border-collapse: collapse;
	}

	.supporting-text-space {
		padding: 3em 6em;
	}

		.supporting-text-space p {font-size: 16px;}

	.post-tags-links {
		margin: 5px 0;
	}

		.post-tags-links a {
			font-weight: normal;
			padding:0 5px;
			color: purple;
			text-decoration: none;
		}

			.post-tags-links a:hover {text-decoration: underline;}

/*estilos para tabela de nomes de A atÃ© Z*/
table.custom-td td {text-align: center;}
	table.custom-td tr:last-child {border-top: 1px solid #DDD;}
	table.custom-td tr + tr {border-top: 1px solid #DDD;}

	table.custom-td td + td {
		border-left: 1px solid #DDD;
		text-align: center;
	}

		table.custom-td td:hover {background: #EAEAEA;}

		table.custom-td td a {
			text-decoration: none;
			color: #666;
			padding: 10px;
			display: block;
		}

			table.custom-td td a:hover {color: #000}

	.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-m .mdl-card__title-text,.mdl-card-sonhos-nomes.mdl-card-sonhos-nomes-f .mdl-card__title-text {color:#FFF; z-index: 500;}

.list-names {
	margin:0;
	padding: 0;
	list-style: none;
}

	.list-names li {
		width: 25%;
		box-sizing: border-box;
		display: inline-block;
		float: left;
		font-size: 1.2em;
	}
		.list-names.a-z li {width: 7.6%; text-align: center}

	.list-names li a {
		color: #666;
		padding: .5em;
		display: block;
		text-decoration: none;
		border-radius: 3px;
		transition: all .2s ease;
		font-weight: normal;
		/*font-weight: bold;*/
	}

		.list-names li a:hover {background: #EDEdED;transition: all .2s ease;}

		.sonhos-footer .mdl-logo {padding-top: 24px;}

/**** desktop ****/
@media (max-width: 1900px) {
		html,body {font-size: 100%}
}

/**** desktop ****/
@media (max-width: 1200px) {
		html,body {font-size: 95%}
		.box-post-horoscopo a {
			width: 16.666%;
		}
}

/**** Mobile layout ****/
@media (max-width: 1045px) {
	.mdl-card-sonhos-nomes, .mdl-cell.mdl-card-sonhos-nomes, .mdl-card {margin-bottom: 20px!important;}
	#menu-lower-right {display: block;}
	html,body {font-size: 90%}
	.sonhos-header-row {margin:0;}

	.sonhos-topo-logo {display: none;}

	.list-names li {width: 33.3333333333%;}

	.sonhos-header .mdl-layout__drawer-button {display: block;}

	.navigation-links {display: none;}

	.sonhos-search-box {display: block;margin-right: 10px;}
	.sonhos-navigation-container { display: none; }

	.mdl-layout__drawer-button {display: inline-block!important}

	.sonhos-mobile-title {
		display: inline-block !important;
		position: absolute;
		left: calc(50% - 50px);
		top: 5px;
		-webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
						transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	}

	.sonhos-search-box.is-focused ~ .sonhos-mobile-title {opacity: 0;}
	.supporting-text-space {
		padding: 1.5em 3em;
	}

		.supporting-text-space p {font-size: 14px;}

	.input-collapse__inp input[type="text"] {
		/*width: 200px;*/
	}
}

/**** Mobile layout ****/
@media (max-width: 800px) {

	.mdl-card__supporting-text
	{
		line-height: 24px;
		font-size: 16px;
	}
	.list-names li {width: 50%;}
	.supporting-text-space {
		padding: 1.5em;
	}

	.input-collapse__inp input[type="text"] {
		border-radius: 3px;
		width: 100%;
	}

	.list-names.a-z li {width: 10%; text-align: center}

	.bread-crumb {font-size: 0.69em;}

	.mdl-card-post__rate-box {font-size: 1rem;}

	.mdl-card-post .mdl-card__title, .mdl-card-post .mdl-card__supporting-text {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.site-content {
		padding-right: 0;
		padding-left: 0;
	}
}

/**** Mobile layout ****/
@media (max-width: 600px) {
	.sonho-card__cadastro {
		flex-direction: column;
		min-height: auto;
		width: 100%;
		justify-content: center;
	}

		.sonho-card__cadastro .mdl-card__title {padding-bottom: 15px;}
		.sonho-card__cadastro .mdl-card__supporting-text {text-align: center; padding-top: 0;}

	.input-collapse {flex-direction: column;}

		.input-collapse__inp input[type="text"] {
			border-radius: 3px;
			width: 100%;
		}

		.input-collapse__btn {margin: 10px 0 0 0;}

			.input-collapse__btn a {
				padding: 0 1rem;
				border-radius: 3px;
				width: 100%;
			}

	.mdl-card__materias-outras {
		max-width: 100%;
		margin-right: 0;
		margin-left: 0;
	}

	.mdl-card__ads-post, .img-left, .img-right  {
		float: none;
		margin: 0 0 1rem 0;
		display: inline-block;
		width: 100%;
		text-align: center;
	}

	.search-box__field {font-size: 1rem}

	.mdl-card-post__options {flex-direction: column;}

		.mdl-card-post__options-box {width: 100%; border-right:none;}

			.mdl-card-post__options-box:last-child {
				border-left:none;
				border-top: 1px solid #DDD;
			}
			.mdl-card-post__options-box:first-child {
				border-right:none;
				border-bottom: 1px solid #DDD;
			}
	.mdl-card-post__text-navega-lista {list-style: none;}
		.mdl-card-post__text-navega-lista li {padding: 0;}
}

/**** Mobile layout ****/
@media (max-width: 480px) {
	.content-main {order:2}
	.sidebar-main {order:1}
	.box-post-horoscopo a {
		width: 25%;
	}

		.box-post-horoscopo a > span {display: inline-block;}
}
